import React from "react"

import Layout from "components/Layout"
import SubpageHero from "components/SubpageHero"

import { Content } from "page_components/not_found"

const NotFoundPage = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "404",
    }}
  >
    <SubpageHero title="#404" description="Ta strona nie istnieje" />
    <Content />
  </Layout>
)

export default NotFoundPage
